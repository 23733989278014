// extracted by mini-css-extract-plugin
export var banner = "about-module--banner--649f8";
export var bannerImage = "about-module--banner-image--87387";
export var buttonContainer = "about-module--buttonContainer--e5d0e";
export var mainTitle = "about-module--main-title--52279";
export var section = "about-module--section--e2d30";
export var section1Content = "about-module--section1-content--22b88";
export var section1Icon = "about-module--section1-icon--0f2eb";
export var section1IconContainer = "about-module--section1-icon-container--cdb83";
export var section1Text = "about-module--section1-text--9d3dd";
export var section2 = "about-module--section2--c9a26";
export var section2ColumnContainer = "about-module--section-2-column-container--80852";
export var section2Icon = "about-module--section2-icon--0b80d";
export var videoCaption = "about-module--video-caption--212cc";
export var videoContainer = "about-module--video-container--4e1a3";