import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "../components/GatsbyLink"
import Layout from "../components/layouts/Layout.js"
import Banner from "../components/Banner.js"
import * as styles from "../stylesheets/pages/about.module.scss"

const About = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      prismicAbout {
        data {
          banner_header {
            text
          }
          banner_image {
            alt
            url
          }
          banner_sub_header {
            text
          }
          icons {
            icon {
              alt
              url
            }
          }
          section_1_button {
            button_text {
              text
            }
          }
          section_1_header {
            text
          }
          section_1_text {
            richText
          }
          section_2_bullets {
            richText
          }
          section_2_header {
            text
          }
          section_2_icon {
            alt
            url
          }
          section_2_text {
            richText
          }
          section_2_video_description
          section_2_video_url
        }
      }
    }
  `).prismicAbout.data

  const icons = data.icons.map((icon, index) => {
    return (
      <img
        src={icon.icon.url}
        alt={icon.icon.alt}
        className={styles.section1Icon}
        style={{ margin: "auto" }}
        key={index}
      />
    )
  })

  return (
    <Layout
      title="About Us - Worker Owned Tech Support - TechCollective"
      description="TechCollective is a worker-owned MSP, providing IT and tech support to businesses. Learn about what makes us different and how we can support your business."
    >
      <Banner
        subHeader={data.banner_sub_header.text}
        alt={data.banner_image.alt}
      />
      <div className={styles.section}>
        <h2>About Our Cooperative</h2>
        <div className={styles.section1Content}>
          <div className={styles.section1Text}>
            <RichText
              render={data.section_1_text.richText}
              serializeHyperlink={GatsbyLink}
            />
          </div>
          <div className={styles.section1IconContainer}>{icons}</div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Link to="/our-bylaws">
          <button>Our Bylaws</button>
        </Link>
        <Link to="/terms-of-service">
          <button>Terms of Service</button>
        </Link>
        <Link to="/privacy-policy">
          <button>Privacy Policy</button>
        </Link>
      </div>

      <div className={`${styles.section} ${styles.section2}`}>
        <img
          src={data.section_2_icon.url}
          alt={data.section_2_icon.alt}
          className={styles.section2Icon}
        />
        <h2>About Worker-Owned Cooperatives</h2>

        <div>
          <RichText
            render={data.section_2_text.richText}
            serializeHyperlink={GatsbyLink}
          />
        </div>
        <div className={styles.section2ColumnContainer}>
          <div className={styles.videoContainer}>
            <iframe
              width="560"
              height="315"
              src={"https://www.youtube.com/embed/NK9SjSpRCcQ"}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              showinfo="0"
              title="Shift Change"
            ></iframe>
            <p className={styles.videoCaption}>
              {data.section_2_video_description}
            </p>
          </div>
          <div>
            <RichText
              render={data.section_2_bullets.richText}
              serializeHyperlink={GatsbyLink}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
